import * as React from 'react';
import { Text, MainContainer, SEO } from '~/components';
import styled from '@emotion/styled';
import theme from '../styles/theme';

const Title = styled(Text)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const Section = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(4),
  '&:first-of-type': {
    marginTop: theme.spacing(0),
  },
}));

function Impressum() {
  return (
    <>
      <SEO title="Impressum" />
      <MainContainer
        css={{
          alignItems: 'center',
          textAlign: 'center',
          color: theme.colors.sand,
        }}
      >
        <h1
          css={(theme) => ({
            color: theme.colors.rose,
            textTransform: 'uppercase',
            textAlign: 'center',
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(2),
          })}
        >
          Impressum
        </h1>

        <Section>
          <h3 css={{ textTransform: 'uppercase' }}>Adresse</h3>
          <div>
            nahschub KLG
            <br />
            Hönggerstrasse 146
            <br />
            CH – 8037 Zürich
          </div>
        </Section>
        <Section>
          <h3 css={{ textTransform: 'uppercase' }}>Firma</h3>
          <div>
            nahschub KLG
            <br />
            UID: CHE-348.686.957
            <br />
            CH-ID: CH02020086563
          </div>
        </Section>
      </MainContainer>
    </>
  );
}

export default Impressum;
